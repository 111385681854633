<template>
	<div class="calendar-page row-space-tbf">
		<div class="space-left"></div>
		<div class="content">
			<calendar-app />
		</div>
		<div class="space-right"></div>
	</div>
</template>

<script>
    import CalendarApp from './CalendarApp'

	export default {
		components: {
			CalendarApp
		},
		data() {
			return {
			}
		},
		mounted() {
		}
}
</script>